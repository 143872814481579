export const ORANGE_COLOR = '#FF8F00'

export const lightTheme = {
  MainBackGround: '#fafafa',
  ItemsBackGround: '#ffffff',
  ItemsBorders: '#cdcdcd',
  FontColor: '#434343',
  FooterColor: '#ffffff',
}

export const darkTheme = {
  MainBackGround: '#16161c',
  ItemsBackGround: '#333337',
  ItemsBorders: '#333337',
  FontColor: '#ffffff',
  FooterColor: '#333337',
}

export const initialState: IApp = {
  isAppInit: false,
  isProductsLoaded: false,
  isPaymentAvailable: false,
  lastActiveCategoryId: '',
  schedule: '',
  reservationSettings: {
    appTheme: '',
    BranchID: 0,
    colour: null,
    CompanyGUID: '',
    font: '',
    Intern: 0,
    logoImage: null,
    ShowAllergens: 0,
    ShowNote: 0,
    ShowRoom: 0,
  },
  companyData: {
    DatatransActivated: false,
    BexioID: '',
    City: '',
    CompanyID: '',
    CompanyName: '',
    ContractorFirma: '',
    ContractorName: '',
    Currency: '',
    ExistedCashRegisterErrors: '',
    Phone: '',
    PostCode: '',
    RequestOrigin: '',
    Street: '',
  },
  address: {
    Bezeichnung: '',
    Country: '',
    GoogleMapURL: '',
    Intern: 0,
    Mail: '',
    Phone: '',
    QRMenuClientId: 0,
    Street: '',
    Town: '',
    Website: '',
    ZipCode: '',
  },
  menu: {
    bookingVariant: 1,
    BranchID: 0,
    CompanyGUID: '',
    Intern: 0,
    paymentVariant: null,
    selfService: 0,
    isReservationsShown: 1,
    isSocialsShown: 0,
    loginAvailable: false,
    tips: 0,
    notesAvailable: 0,
  },
  interfaceStyles: {
    appTheme: 'light',
    backgroundImage: '',
    BranchID: 0,
    colour: '#e32323',
    CompanyGUID: '',
    font: '',
    Intern: 0,
    LanguageCode: '',
    logoImage: '',
    WaiterCall: 0,
    appIcon: '',
    productViewType: 'tile',
    AppName: '',
    MainBackGround: '#fafafa',
    ItemsBackGround: '#ffffff',
    ItemsBorders: '#cdcdcd',
    FontColor: '#434343',
    FooterColor: '#ffffff',
  },
  options: {
    CompanyGUID: '',
    LeadTimeDays: 0,
    LeadTimeHours: '23:59',
  },
  termsAndConditions: {
    agbText: '',
    dsvgoText: '',
  },
  caption_list: {
    rooms: [],
  },
}

export interface IFetchAppData {
  guid: string
  registerId: string | number
}

export interface IApp {
  isAppInit: boolean
  isProductsLoaded: boolean
  isPaymentAvailable: boolean
  lastActiveCategoryId: string
  companyData: ICompanyData
  address: IAddress
  menu: IMenu
  interfaceStyles: IInterface
  options: IOptions
  schedule: string
  reservationSettings: IReservationSettings
  termsAndConditions: {
    agbText: string
    dsvgoText: string
  }
  caption_list: {
    rooms: IRoom[]
  }
}

export type IAppResponse = {
  config: {
    address: IAddress[]
    menu: IMenu[]
    interface: IInterface[]
    options: IOptions
    caption_list: {
      rooms: IRoom[]
    }
    tac: any
    tablereservationschedule: IReservation[]
    tablereservationinterface: IReservationSettings[]
  }
}[]

export interface ICompanyData {
  DatatransActivated: boolean
  BexioID: string
  City: string
  CompanyID: string
  CompanyName: string
  ContractorFirma: string
  ContractorName: string
  Currency: string
  ExistedCashRegisterErrors: string
  Phone: string
  PostCode: string
  RequestOrigin: string
  Street: string
  BranchName?: string
}

interface IAddress {
  Bezeichnung: string
  Country: string
  GoogleMapURL: string
  Intern: number
  Mail: string
  Phone: string
  QRMenuClientId: number
  Street: string
  Town: string
  Website: string
  ZipCode: string
}

interface IMenu {
  bookingVariant: 1 | 2 | 3
  BranchID: number
  CompanyGUID: string
  Intern: number
  paymentVariant: 1 | 2 | null
  selfService: 0 | 1
  isReservationsShown: 0 | 1
  isSocialsShown: 0 | 1
  tips: number
  notesAvailable: 0 | 1
  loginAvailable: boolean
  isUrlExtended?: boolean
}

export interface IInterface {
  AppName: string
  appTheme: 'light' | 'dark'
  backgroundImage: string
  BranchID: number
  colour: string
  CompanyGUID: string
  font: string
  Intern: number
  LanguageCode: string
  logoImage: string
  WaiterCall: 1 | 0
  appIcon: string
  productViewType: 'list' | 'tile'
  MainBackGround: string
  ItemsBackGround: string
  ItemsBorders: string
  FontColor: string
  FooterColor: string
}

interface IOptions {
  CompanyGUID: string
  LeadTimeDays: number
  LeadTimeHours: string
}

interface IRoom {
  id: number
  caption: string
}

interface IReservation {
  BookingPossibility: number
  BookingTimeIntervals: string
  BranchID: number
  CompanyGUID: string
  Intern: number
}

interface IReservationSettings {
  appTheme: string
  BranchID: number
  colour: string | null
  CompanyGUID: string
  font: string
  Intern: number
  logoImage: string | null
  ShowAllergens: number
  ShowNote: number
  ShowRoom: number
}
