import { CommonSlice } from '.'
import { AppDispatch } from '../../index'
import { IAllergen, IFetchTableData, ITableData } from './types'
import { handleLocalStorage } from '../../../utils/handleLocalStorage'
import { qrMenuGET } from '../../../api/qrMenu/GET'

export const detectDefaultLanguage = () => async (dispatch: AppDispatch) => {
  let userLang = handleLocalStorage('lang')
  if (!userLang) {
    userLang = navigator.language?.slice(0, 2)
  }
  userLang = ['de', 'fr', 'it', 'en'].find(
    (currentLang) => currentLang === userLang
  )
  dispatch(CommonSlice.actions.setSelectedLanguage(userLang || 'de'))
}

export const getAllergens = () => async (dispatch: AppDispatch) => {
  const allergens: IAllergen[] = await qrMenuGET.allergens()
  dispatch(CommonSlice.actions.setAllergens(allergens))
}

export const getTableData =
  (params: IFetchTableData) => async (dispatch: AppDispatch) => {
    const { caption, tableId } = params
    const tableData: ITableData = await qrMenuGET.tableInfo(caption, tableId)
    dispatch(CommonSlice.actions.setTableData(tableData))
  }
