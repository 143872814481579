import { Auth } from '@aws-amplify/auth'
import { load as WebFontLoader } from 'webfontloader'
import { registerManifest } from '../../../utils/registerManifest'
import { qrMenuGET } from '../../../api/qrMenu/GET'
import { setFont } from '../../../utils/setFont'
import { AppDispatch } from '../../index'
import { AppSlice } from '.'

import {
  darkTheme,
  IAppResponse,
  ICompanyData,
  IInterface,
  initialState,
  lightTheme,
} from './types'
import { downloadCustomFont } from '../../../utils/downloadCustomFont'

export const getAppData = () => async (dispatch: AppDispatch) => {
  const setupRequest = new Promise<IAppResponse[0]>((resolve, reject) => {
    ;(async () => {
      try {
        const setup: IAppResponse = await qrMenuGET.setup()
        resolve(setup?.[0])
      } catch (err) {
        reject(err)
      }
    })()
  })
  const companyDataRequest = new Promise<ICompanyData>((resolve, reject) => {
    ;(async () => {
      try {
        const companyData: ICompanyData = await qrMenuGET.companyData()
        resolve(companyData)
      } catch (err) {
        reject(err)
      }
    })()
  })
  const loadCustomFont = new Promise<null>((resolve) => {
    ;(async () => {
      try {
        downloadCustomFont()
        resolve(null)
      } catch (err) {
        console.log('Font loading error:', err)
      }
    })()
  })
  Promise.all([setupRequest, companyDataRequest, loadCustomFont]).then(
    ([setup, companyData]: [IAppResponse[0], ICompanyData, null]) => {
      console.log('setup:', setup)
      if (setup?.config?.interface?.[0]?.font) {
        WebFontLoader({
          google: {
            families: [setup?.config?.interface?.[0]?.font],
          },
        })
        setFont(setup?.config?.interface?.[0]?.font)
      }
      registerManifest(
        companyData?.CompanyName || companyData?.ContractorName || '',
        setup?.config?.interface?.[0]?.appIcon ||
          setup?.config?.interface?.[0]?.logoImage
      )
      if (!setup?.config?.menu?.[0]?.loginAvailable) {
        Auth.signOut()
        window.localStorage.removeItem('userName')
      }

      dispatch(
        AppSlice.actions.fetchAppData({
          isAppInit: true,
          companyData,
          address: setup?.config?.address?.[0],
          menu: setup?.config?.menu?.[0],
          interfaceStyles: getInterfaceStyles({
            ...initialState.interfaceStyles,
            ...setup?.config?.interface?.[0],
          }),
          caption_list: setup?.config?.caption_list,
          options: setup?.config?.options,
          reservationSettings: setup?.config?.tablereservationinterface?.[0],
          termsAndConditions: setup?.config?.tac?.[0],
          schedule:
            setup?.config?.tablereservationschedule?.[0]?.BookingTimeIntervals,
        })
      )
    }
  )
}

const getInterfaceStyles = (styles: Partial<IInterface>) => {
  switch (styles?.appTheme) {
    case 'dark':
      return {
        ...styles,
        ...darkTheme,
      }
    case 'light':
      return {
        ...styles,
        ...lightTheme,
      }
    default:
      return {
        ...styles,
        ...lightTheme,
      }
  }
}
