import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { RenderIf } from '../RenderIf'
import { Button } from '../Button'

import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { useTranslations } from '../../hooks/useTranslations'
import { useMySelector } from '../../hooks/useMySelector'
import { GridDiv } from '../../utils/gridDiv'

import { atcb_action } from 'add-to-calendar-button'
import 'add-to-calendar-button/assets/css/atcb.css'

import {
  ReceiptModalContainer,
  MessageContainerNoReceipt,
  ButtonContainer,
} from './styled'
import { useSearchParams } from 'react-router-dom'
import { clickCollectPOST } from '../../api/clickCollect/POST'
import { ReceiptLoading } from './ReceiptLoading'
import { ReceiptError } from './ReceiptError'

type NoReceiptModalProps = {
  paymentNumberProps?: string
  onCloseModal: () => void
}

type IStatus = 'isLoading' | 'success' | 'error'

export const NoReceiptModal: FC<NoReceiptModalProps> = ({
  paymentNumberProps,
  onCloseModal,
}) => {
  // Hooks
  const t = useTranslations()

  // Search params
  const [searchParams] = useSearchParams()
  const datatransTrxId = searchParams.get('datatransTrxId')
  const order_guid = searchParams.get('order_guid')

  // Store
  const { restaurants } = useMySelector((state) => state)

  const { CompanyName, ContractorName } = useMySelector(
    (state) => state.app.companyData
  )
  const { selectedLanguage } = useMySelector((state) => state.common)
  const { appTheme } = useMySelector((state) => state.app.interfaceStyles)
  const { pickUpDelay, pickUpTime, registerId } = handleLocalStorage([
    'pickUpDelay',
    'pickUpTime',
    'registerId',
  ])

  // State
  const [status, setStatus] = useState<IStatus>('isLoading')

  const [payment_success_nr, setPayment_success_nr] = useState('')
  const [isShowCalendarButton, seIsShowCalendarButton] = useState(true)

  // useEffects
  useEffect(() => {
    if (!pickUpDelay && !pickUpTime) {
      seIsShowCalendarButton(false)
    } else {
      seIsShowCalendarButton(true)
    }
  }, [pickUpDelay, pickUpTime])

  useEffect(() => {
    if (paymentNumberProps) {
      setPayment_success_nr(paymentNumberProps)
      setStatus('success')
    }
    if (!paymentNumberProps) {
      triggerPaymentComplete()
    }
  }, [paymentNumberProps])

  // Functions
  const triggerPaymentComplete = async () => {
    if (datatransTrxId && order_guid) {
      try {
        const clickResult = await clickCollectPOST.paymentComplete(
          datatransTrxId,
          order_guid
        )
        if (clickResult?.orderNr) {
          setPayment_success_nr(clickResult.orderNr)
          setStatus('success')
        }
      } catch (e) {
        setStatus('error')
      }
    }
  }

  const getDateTimeArray = () => {
    if (pickUpDelay && !pickUpTime) {
      const DD = moment().add(pickUpDelay, 'minutes').format('YYYY-MM-DD')
      const TT = moment().add(pickUpDelay, 'minutes').format('HH:mm')
      return [DD, TT]
    }
    if (!pickUpDelay && pickUpTime) {
      const DD = moment(pickUpTime).format('YYYY-MM-DD')
      const TT = moment(pickUpTime).format('HH:mm')
      return [DD, TT]
    }
    return []
  }

  const addToCalendar = () => {
    const [DD, TT] = getDateTimeArray()
    const address =
      restaurants?.find((rest) => {
        return rest?.clickAndCollectClientId === registerId
      })?.address || ''
    const config = {
      label: `${CompanyName} order`,
      name: `Pick up order Nr.${payment_success_nr} at ${
        CompanyName || ContractorName
      }`,
      description: `Don't forget to pick up your ${
        CompanyName || ContractorName
      } order Nr.${payment_success_nr} at ${TT}`,
      location: address,

      startDate: DD,
      endDate: DD,
      startTime: TT,
      endTime: TT,

      options: ['Apple', 'Google', 'Yahoo', 'Microsoft365'] as any,

      lightMode: appTheme,
      language: selectedLanguage,
      timeZone: 'Europe/Zurich',
    }
    atcb_action(config)
  }

  return (
    <>
      <RenderIf condition={status === 'isLoading'}>
        <ReceiptLoading />
      </RenderIf>
      <RenderIf condition={status === 'error'}>
        <ReceiptError />
      </RenderIf>
      <RenderIf condition={status === 'success'}>
        <ReceiptModalContainer>
          <GridDiv gc="1 / -1">
            <MessageContainerNoReceipt>
              <h2>
                {t('receipt.paymentSuccessOrderNumber', {
                  orederNumber: payment_success_nr,
                })}
              </h2>
            </MessageContainerNoReceipt>
          </GridDiv>

          <GridDiv gc="1 / -1">
            <RenderIf condition={!isShowCalendarButton}>
              <Button buttonType="stroke" width="100%" onClick={onCloseModal}>
                {t('buttons.ok')}
              </Button>
            </RenderIf>
            <RenderIf condition={isShowCalendarButton}>
              <ButtonContainer>
                <Button buttonType="stroke" width="100%" onClick={onCloseModal}>
                  {t('buttons.close')}
                </Button>
                <Button
                  buttonType="common"
                  width="100%"
                  onClick={addToCalendar}
                >
                  {t('buttons.addToCalendar')}
                </Button>
              </ButtonContainer>
            </RenderIf>
          </GridDiv>
        </ReceiptModalContainer>
      </RenderIf>
    </>
  )
}
