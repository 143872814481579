import { FC, useState, useEffect, useRef } from 'react'
import { A2hs } from './A2hs'
import InfoItem from './InfoItem'
import { Languages } from './Languages'
import { Button } from '../../components/Button'
import { RenderIf } from '../../components/RenderIf'
import { NavLink, useSearchParams } from 'react-router-dom'
import { getAuthUserName } from '../../utils/getAuthUserName'
import { useMySelector } from '../../hooks/useMySelector'
import { AskWaiterModal } from './AskWaiterModal'
import { AuthModal } from './AuthModal'
import { Profile } from './Profile'
import { Menu } from './Profile/Menu'
import { ShareButtons } from './ShareButtons'
import { qrMenuGET } from '../../api/qrMenu/GET'
import { useOpenModal } from '../../hooks/useOpenModal'
import { useMyDispatch } from '../../hooks/useMyDispatch'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { getStatusWithInterval } from '../../utils/getStatusWithInterval'
import { TableDataResType } from '../../api/mobileWaiter/types'
import { useTranslations } from '../../hooks/useTranslations'
import { AppSlice } from '../../store/reducers/AppReducer'
import { NotificationsModal } from './NotificationsModal'
import history from '../../utils/history'

import { mobileWaiterGET } from '../../api/mobileWaiter/GET'
import { mobileWaiterPOST } from '../../api/mobileWaiter/POST'
import { qrMenuPOST } from '../../api/qrMenu/POST'

import { ReactComponent as Markers } from '../../assets/cover/markersStroke.svg'
import { ReactComponent as Marker } from '../../assets/cover/markerStroke.svg'
import { ReactComponent as CellPhone } from '../../assets/cover/cellPhone.svg'
import { ReactComponent as CrossImg } from '../../assets/handlers/cross.svg'
import { ReactComponent as Burger } from '../../assets/cover/burger.svg'
import { ReactComponent as Account } from '../../assets/account.svg'
import { ReactComponent as Bell } from '../../assets/bell.svg'
import backgroundVideo from '../../assets/backgroundVideoTEMP.mp4'
import clickAndCollectLogo from '../../assets/prompt/clickAndCollect.svg'
import qrMenuLogo from '../../assets/prompt/qrMenu.svg'

import {
  CoverStyled,
  CoverInnerBlock,
  CoverLogo,
  CoverSidebar,
  CoverSidebarCloseHandler,
  SidebarButtonContainer,
  RestaurantInfoBanner,
  SidebarCloser,
  InstructionsBlock,
  SidebarLink,
  NotificationsButtonContainer,
  VideoBackground,
  AGBText,
} from './styled'
import { TermsConditions } from '../TermsConditions'
import { AGBModal } from '../TermsConditions/AGB'

export type PageType = 'edit' | 'orders' | 'card' | 'payment' | null

export const MainScreen: FC = () => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const [searchParams] = useSearchParams()
  const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])

  // Store
  const { setIsPaymentAvailable } = AppSlice.actions
  const { restaurants } = useMySelector((state) => state)
  const { interfaceStyles, companyData, isPaymentAvailable, address } =
    useMySelector((state) => state.app)
  const {
    paymentVariant,
    bookingVariant,
    selfService,
    isReservationsShown,
    isSocialsShown,
    loginAvailable,
  } = useMySelector((state) => state.app.menu)

  // State
  const [isSidebarShowing, setIsSidebarShowing] = useState<boolean>(false)
  const [orderState, setOrderState] = useState<0 | 1>(1)
  const [tableData, setTableData] = useState<TableDataResType | null>(null)

  const [notificationsAmount, setNotificationsAmount] = useState<
    number | string
  >(0)
  const [isAuthorized, setIsAuthorized] = useState<string | boolean>(false)
  const currentPage = useRef<PageType>(null)

  // useEffects
  useEffect(() => {
    getIsAuthorized()
    setIsSidebarShowing(false)
  }, [])

  useEffect(() => {
    if (guid) {
      getNotificationsAmount()
    }
  }, [guid])

  useEffect(() => {
    checkIsPaymentDisabled()
  }, [bookingVariant])

  // Functions
  const getIsAuthorized = async () => {
    const value = await getAuthUserName()
    setIsAuthorized(value)
  }

  const getNotificationsAmount = async () => {
    if (process.env.REACT_APP_IS_CLIC === '1') {
      const amount = await qrMenuGET.notificationsAmount()
      if (amount > 9) {
        setNotificationsAmount('9+')
      } else {
        setNotificationsAmount(amount)
      }
    }
  }

  const onLoginRequest = async () => {
    await getIsAuthorized()
  }

  const openNotificationsModal = () => {
    openModal({
      id: 'NOTIFICATIONS_MODAL',
      title: t('common.notifications'),
      onClose: getNotificationsAmount,
      components: [
        ({ closeThisModal }) => (
          <NotificationsModal
            onDeclineClose={closeThisModal}
            onSuccessClose={closeThisModal}
          />
        ),
      ],
    })
  }

  const openAuthModal = () => {
    openModal({
      id: 'AUTH_MODAL',
      title: '',
      components: [() => <AuthModal onLogin={onLoginRequest} />],
    })
  }

  const openTermsAndConditions = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'TERMS_AND_CONDITIONS',
      title: 'Terms and Conditions',
      components: [() => <TermsConditions />],
    })
  }

  const openAGB = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'AGB_MODAL',
      title: 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
      components: [() => <AGBModal />],
    })
  }

  const openProfileModal = (startPage?: PageType) => {
    if (startPage) {
      currentPage.current = startPage
    }
    openModal({
      id: 'PROFILE_MODAL',
      title: 'Profile',
      startIndex: startPage ? 1 : 0,
      components: [
        ({ onNext, closeThisModal }) => (
          <Menu
            onNext={onNext}
            closeThisModal={closeThisModal}
            setCurrentPage={(value) => (currentPage.current = value)}
          />
        ),
        () => (
          <Profile
            currentPage={currentPage.current}
            setCurrentPage={(value) => (currentPage.current = value)}
          />
        ),
      ],
      onClose: () => {
        getIsAuthorized()
        getNotificationsAmount()
      },
    })
  }

  const handleLoyaltyButtonClick = () => {
    if (isAuthorized) openProfileModal('card')
    if (!isAuthorized) openAuthModal()
  }

  const getNavLink = (path = 'menu') => {
    const tableId = searchParams.get('table')
    if (tableId) {
      return `/${path}?table=${tableId}`
    } else {
      return `/${path}`
    }
  }

  const checkIsPaymentDisabled = async () => {
    if (bookingVariant === 3) {
      dispatch(setIsPaymentAvailable(true))
    } else if (paymentVariant === 2 && process.env.REACT_APP_IS_CLIC === '0') {
      const tableId = searchParams.get('table')
      if (tableId) {
        try {
          const guid = handleLocalStorage('guid')
          if (guid) {
            Promise.allSettled([
              qrMenuGET.paymentDetails(+tableId),
              mobileWaiterGET.tableStatus(tableId),
            ]).then(([paymentDetails, tableStatus]) => {
              if (paymentDetails.status === 'fulfilled') {
                if (paymentDetails?.value?.orderState) {
                  setOrderState(paymentDetails?.value?.orderState)
                }
                dispatch(setIsPaymentAvailable(true))
              } else if (
                tableStatus?.status === 'fulfilled' &&
                tableStatus?.value?.status === 'BUSY'
              ) {
                setOrderState(1)
                setTableData(tableStatus.value.tableData)
                dispatch(setIsPaymentAvailable(true))
              } else {
                dispatch(setIsPaymentAvailable(false))
              }
            })
          }
        } catch (e) {
          dispatch(setIsPaymentAvailable(false))
        }
      } else {
        dispatch(setIsPaymentAvailable(false))
      }
    }
  }

  const handlePayClick = () => {
    if (!tableData) {
      openAskWaiterModal()
    }
    if (tableData) {
      createOrderAndRedirect()
    }
  }

  const openAskWaiterModal = () => {
    openModal({
      id: 'ASK_WAITER',
      components: [
        ({ closeThisModal }) => <AskWaiterModal onClose={closeThisModal} />,
      ],
    })
  }

  const createOrderAndRedirect = async () => {
    const { restaurantId = '', tableId = 0 } = tableData || {}
    const requestGUID = await mobileWaiterPOST.migrateItems(
      restaurantId,
      tableId
    )
    if (requestGUID?.length) {
      const statusData = await getStatusWithInterval(requestGUID)
      const { orderItems, errorMessage } = statusData?.tableData || {}
      if (errorMessage?.length) {
        openAskWaiterModal()
      } else if (orderItems?.length) {
        await qrMenuPOST.bookProducts(tableId.toString(), orderItems, true)
        history.push(getNavLink('payment'))
      }
    }
    return
  }

  const getImageSrc = (logoImage?: string) => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      return logoImage || qrMenuLogo
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      return logoImage || clickAndCollectLogo
    }
  }

  const navigateToReservation = () => {
    history.push('/reservation')
  }

  return (
    <CoverStyled>
      <RenderIf
        condition={Boolean(
          guid === '9712049C-1325-430F-B0C8-F6D64E5CF9C5' && registerId == 67
        )}
      >
        <VideoBackground
          src={
            'https://video-reis-rolle.s3.eu-central-1.amazonaws.com/backgroundVideoTEMP.mp4'
          }
          typeof="video/mp4"
          controls={false}
          playsInline
          autoPlay
          muted
          loop
        />
      </RenderIf>
      {/* CROSS BUTTON */}
      <SidebarCloser
        isSidebarShowing={isSidebarShowing}
        onClick={() => setIsSidebarShowing(false)}
      >
        <CoverSidebarCloseHandler isSidebarShowing={isSidebarShowing}>
          <Button buttonType="common" height="38px" width="38px">
            <CrossImg width="15" height="15" />
          </Button>
        </CoverSidebarCloseHandler>
      </SidebarCloser>

      {/* SIDEBAR */}
      <CoverSidebar isSidebarShowing={isSidebarShowing}>
        <RenderIf condition={loginAvailable && !isAuthorized}>
          <InfoItem
            title={t('sidebar.account')}
            Icon={Account}
            data={[t('sidebar.logInSignUp')]}
            onClick={openAuthModal}
          />
        </RenderIf>
        <RenderIf condition={loginAvailable && !!isAuthorized}>
          <InfoItem
            title={t('sidebar.account')}
            Icon={Account}
            data={[isAuthorized]}
            onClick={() => openProfileModal()}
          />
        </RenderIf>
        <RenderIf condition={!!companyData.CompanyName}>
          <RestaurantInfoBanner>{companyData.CompanyName}</RestaurantInfoBanner>
        </RenderIf>
        <RenderIf
          condition={
            process.env.REACT_APP_IS_CLIC === '0' &&
            Boolean(isReservationsShown)
          }
        >
          <InfoItem
            title={t('profile.toReservate')}
            Icon={CellPhone}
            components={
              <>
                <SidebarLink href={`tel:${companyData.Phone}`}>
                  {companyData.Phone}
                </SidebarLink>
                <SidebarLink onClick={navigateToReservation}>
                  Online
                </SidebarLink>
              </>
            }
          />
        </RenderIf>
        <InfoItem
          title={t('sidebar.address')}
          Icon={Marker}
          data={[
            companyData.CompanyName,
            companyData.Street,
            `${companyData.PostCode || ''}${companyData.PostCode ? ', ' : ''}${
              companyData.City || ''
            }`,
          ]}
        />
        {/*  */}
        <RenderIf
          condition={
            process.env.REACT_APP_IS_CLIC === '0' &&
            Boolean(!isReservationsShown)
          }
        >
          <InfoItem
            title={t('tableReservation.contactInfo')}
            Icon={CellPhone}
            components={
              <SidebarLink href={`tel:${address.Phone}`}>
                {address.Phone}
              </SidebarLink>
            }
            data={[address.Mail, address.Website]}
          />
        </RenderIf>
        {/*  */}
        <RenderIf condition={restaurants?.length > 1}>
          <InfoItem
            title={t('sidebar.moreRestaurants')}
            Icon={Markers}
            link={`restaurants`}
            data={[t('sidebar.locations')]}
          />
        </RenderIf>
        <RenderIf condition={Boolean(isSocialsShown)}>
          <ShareButtons />
        </RenderIf>
        <AGBText>
          <span aria-hidden="true" onClick={openAGB}>
            den AGB
          </span>{' '}
          und{' '}
          <span aria-hidden="true" onClick={openTermsAndConditions}>
            DSGVO
          </span>{' '}
        </AGBText>
      </CoverSidebar>

      {/*  */}

      <SidebarButtonContainer>
        <Burger
          width="38"
          height="38"
          onClick={() => setIsSidebarShowing(true)}
        />
      </SidebarButtonContainer>

      <RenderIf
        condition={
          (selfService === 0 && interfaceStyles.WaiterCall === 1) ||
          process.env.REACT_APP_IS_CLIC === '1'
        }
      >
        <NotificationsButtonContainer counter={notificationsAmount}>
          <Bell width="38" height="38" onClick={openNotificationsModal} />
        </NotificationsButtonContainer>
      </RenderIf>

      <CoverInnerBlock>
        <CoverLogo
          src={getImageSrc(interfaceStyles.logoImage)}
          alt="Logo"
          height="316.5"
          width="316.5"
        />
      </CoverInnerBlock>

      <CoverInnerBlock
        withInstructions={
          process.env.REACT_APP_IS_CLIC === '0' && selfService === 1
        }
      >
        <RenderIf condition={!!restaurants?.length && bookingVariant !== 3}>
          <NavLink to={getNavLink('menu')}>
            <Button buttonType="common">{t('cover.menu')}</Button>
          </NavLink>
        </RenderIf>

        <RenderIf
          condition={
            (bookingVariant === 3 || paymentVariant === 2) &&
            process.env.REACT_APP_IS_CLIC === '0'
          }
        >
          <RenderIf condition={orderState === 0}>
            <NavLink to={getNavLink('payment')}>
              <Button buttonType="stroke" disabled={!isPaymentAvailable}>
                {t('cover.pay')}
              </Button>
            </NavLink>
          </RenderIf>

          <RenderIf condition={orderState === 1}>
            <Button
              buttonType="stroke"
              disabled={!isPaymentAvailable}
              onClick={handlePayClick}
            >
              {t('cover.pay')}
            </Button>
          </RenderIf>
          {/*  */}
        </RenderIf>

        <RenderIf
          condition={process.env.REACT_APP_IS_CLIC === '1' && loginAvailable}
        >
          <Button
            buttonType={restaurants?.length ? 'stroke' : 'common'}
            onClick={handleLoyaltyButtonClick}
          >
            {t('profile.card')}
          </Button>
        </RenderIf>

        <RenderIf
          condition={process.env.REACT_APP_IS_CLIC === '0' && selfService === 1}
        >
          <InstructionsBlock>
            <p>1. {t('mainPage.orderAndPay')}</p>
            <p>2. {t('mainPage.smsRecieve')}</p>
            <p>3. {t('mainPage.counter')}</p>
          </InstructionsBlock>
        </RenderIf>
        <Languages />
        <A2hs />
      </CoverInnerBlock>
    </CoverStyled>
  )
}

export default MainScreen
