import styled from 'styled-components'

const ITEM_HEIGHT = 75

type PriceProps = {
  isSoldOut?: boolean
}

type ImageContainerProps = {
  background?: string
  count?: number
}

type NameProps = {
  altCount?: number
}

type ListItemProps = {
  isSelected?: boolean
}

export const AllergensContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  align-items: center;
`

export const Allergen = styled.img`
  background: #cdcdcd;
  height: 20px;
  width: 20px;
  object-fit: cover;
  margin-left: 5px;
  border-radius: 13px;
`

export const AgeProtection = styled.p`
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: #cdcdcd;
  color: #000;
  width: 24px;
  height: 24px;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding-top: 1px;
  z-index: 9;
`

export const ImageContainer = styled('div')<ImageContainerProps>`
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  height: ${ITEM_HEIGHT}px;
  width: ${ITEM_HEIGHT}px;
  position: relative;
  box-sizing: border-box;
  background: ${({ background }) => background || 'unset'};
  &::after {
    content: ${({ count }) => (count ? `'${count}'` : 'unset')};
    position: absolute;
    color: #fff;
    background: ${({ theme }) => theme.colour};
    height: 20px;
    width: 20px;
    border-radius: 10px;

    text-align: center;
    right: 0;
    top: 0;
    font-size: 12px;
    padding-top: 1px;
    z-index: 2;
  }
`

export const IncludedItems = styled.div`
  grid-column: 2 / -1;
  margin-top: 0.5rem;
  p {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
`

export const KitchenItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 5px;
`

export const KitchenItem = styled.div`
  color: #fff;
  background-color: ${({ theme }) => theme.colour};
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  line-height: 100%;
`

export const Name = styled('div')<NameProps>`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  margin: 0;
  margin-left: 10px;
  height: 50px;
  line-height: 25px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  p {
    position: relative;
    margin: 0;
  }
  p:after {
    content: ${({ altCount }) => (altCount ? `'${altCount}'` : 'unset')};
    position: absolute;
    top: 0;
    left: calc(100% + 5px);
    border: ${({ theme }) => `1px solid ${theme.colour}`};
    color: ${({ theme }) => theme.colour};
    width: 22px;
    height: 22px;
    border-radius: 100%;
    text-align: center;
    line-height: 21px;
  }
`

export const Note = styled.div`
  grid-column: 2 / 3;
  grid-row: 3 / -1;
  margin: 0;
  margin-left: 10px;
  height: 25px;
  overflow: hidden;
  display: flex;
`

export const Price = styled('div')<PriceProps>`
  grid-column: 3 / -1;
  grid-row: 1 / -1;
  margin: 0;
  line-height: 25px;
  color: ${({ theme, isSoldOut }) => (isSoldOut ? '#cdcdcd40' : theme.colour)};
  font-weight: 500;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: flex-end;
`

export const ProductStyled = styled('div')<ListItemProps>`
  margin: 3vw 0;
  box-sizing: content-box;
  border-radius: 5px;
  position: relative;
  background: #fff;

  background: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
  scroll-margin-top: 140px;

  padding: 5px;
  display: grid;
  grid-template-columns: ${ITEM_HEIGHT}px 1fr ${ITEM_HEIGHT}px;
  grid-template-rows: ${ITEM_HEIGHT / 3}px ${ITEM_HEIGHT / 3}px ${ITEM_HEIGHT /
    3}px 1fr;

  border: ${({ theme, isSelected }) =>
    isSelected
      ? `1px solid ${theme.colour}`
      : `1px solid ${theme.ItemsBorders}`};
`

export const StyledImage = styled.img`
  position: absolute;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 100%;
`

export const SecondaryImage = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
`
