import { CategoriesSlice } from '.'
import { AppDispatch } from '../../index'
import { ICategory, IKitchenTimeSlot, IProduct } from './types'
import { ProductsSlice } from '../ProductsReducer'
import { errorHandler } from '../../../components/ErrorHandler'
import moment, { Moment } from 'moment'
import { handleLocalStorage } from '../../../utils/handleLocalStorage'
import { setTimeKeepDate } from '../../../utils/setTimeKeppDate'
import { qrMenuGET } from '../../../api/qrMenu/GET'

export const getCategoriesData =
  (search?: string) => async (dispatch: AppDispatch) => {
    try {
      const kitchenTimeSlots = await qrMenuGET.getKitchenTimeSlots()
      const categories: ICategory[] = await getCategoriesFiltered(
        search,
        kitchenTimeSlots
      )
      const products = categories?.reduce(
        (categoriesAcc: IProduct[], category: ICategory) => {
          return [...categoriesAcc, ...category.products]
        },
        []
      )
      dispatch(CategoriesSlice.actions.fetchCategories(categories))
      dispatch(ProductsSlice.actions.setProducts(products))
    } catch (e) {
      errorHandler(e)
    }
  }

// TODO: move getCategoriesFiltered from AC
const getCategoriesFiltered = async (
  search = '',
  kitchenTimeSlots: IKitchenTimeSlot[]
) => {
  const categories = await qrMenuGET.products(search)
  return categories?.map((category) => ({
    ...category,
    products: category?.products?.filter((product) =>
      isProductAvailable(product, kitchenTimeSlots)
    ),
  }))
}

const isProductAvailable = (
  product: IProduct,
  kitchenTimeSlots: IKitchenTimeSlot[]
) => {
  if (process.env.REACT_APP_IS_CLIC === '0') {
    return getProductAvailabilityQRM(product, kitchenTimeSlots)
  }
  if (process.env.REACT_APP_IS_CLIC === '1') {
    return getProductAvailabilityClick(product, kitchenTimeSlots)
  }
}

const getProductAvailabilityQRM = (
  product: IProduct,
  kitchenTimeSlots: IKitchenTimeSlot[]
) => {
  if (!product.productTimeSlot) {
    return true
  } else {
    const neededSlot = getNeededSlot(product, kitchenTimeSlots)
    return moment().isBetween(
      moment(neededSlot?.timeStart, 'hh:mm'),
      moment(neededSlot?.timeEnd, 'hh:mm')
    )
  }
}

const getProductAvailabilityClick = (
  product: IProduct,
  kitchenTimeSlots: IKitchenTimeSlot[]
) => {
  if (!product.productTimeSlot) {
    return true
  } else {
    const { pickUpDelay, pickUpTime } = handleLocalStorage([
      'pickUpDelay',
      'pickUpTime',
    ])

    const PickupTime = pickUpTime
      ? moment(pickUpTime)
      : moment().add(pickUpDelay, 'minutes')

    const neededSlot = getNeededSlot(product, kitchenTimeSlots, PickupTime)
    const startMoment = setTimeKeepDate(PickupTime, neededSlot?.timeStart || '')
    const endMoment = setTimeKeepDate(PickupTime, neededSlot?.timeEnd || '')

    return PickupTime.isBetween(startMoment, endMoment)
  }
}

const getNeededSlot = (
  product: IProduct,
  kitchenTimeSlots: IKitchenTimeSlot[],
  date?: Moment
) => {
  const weekday = date ? date.isoWeekday() : moment().isoWeekday()
  return kitchenTimeSlots
    ?.find((slot) => slot.timeSlotCode === product.productTimeSlot)
    ?.schedule?.find((item) => item.day === weekday)
}
