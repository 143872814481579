import { FC } from 'react'
import { Button } from '../../../../components/Button'
import { RenderIf } from '../../../../components/RenderIf'
import { useMySelector } from '../../../../hooks/useMySelector'
import { useTranslations } from '../../../../hooks/useTranslations'
import { clickCollectPOST } from '../../../../api/clickCollect/POST'
import { convertToPrice } from '../../../../utils/convertToPrice'
import { GridDiv } from '../../../../utils/gridDiv'
import { CurrentPageType, ProductToPay } from '../../types'

type CCFooterProps = {
  total: number
  currentPage: CurrentPageType
  tipsValue: number
  productsToPay: ProductToPay[]
  enableTips: () => void
  enableSelfService: () => void
  isSelfServicePayDisabled: boolean
  useSavedCard: boolean
  updateActiveCard: boolean
  handlePayment: (value: () => Promise<any>) => Promise<boolean | undefined>
  isLoading: boolean
}

export const CCFooter: FC<CCFooterProps> = ({
  currentPage,
  productsToPay,
  enableSelfService,
  isSelfServicePayDisabled,
  useSavedCard,
  updateActiveCard,
  handlePayment,
  isLoading,
}) => {
  // Hooks
  const t = useTranslations()

  const paymentRequest = async () => {
    const res = await handlePayment(async () => {
      const { transactionId, orderNr } = await clickCollectPOST.createOrder(
        productsToPay,
        !useSavedCard,
        updateActiveCard
      )
      return { TransactionId: transactionId, orderNr }
    })
    return res
  }

  return (
    <>
      <RenderIf condition={currentPage === 'cart'}>
        <GridDiv gc="1 / -1">
          <Button
            buttonType="common"
            width="100%"
            isLoading={isLoading}
            onClick={enableSelfService}
          >
            {t('auth.confirmButton')}
          </Button>
        </GridDiv>
      </RenderIf>
      <RenderIf condition={currentPage === 'selfService'}>
        <GridDiv gc="1 / -1">
          <Button
            buttonType="common"
            width="100%"
            disabled={isSelfServicePayDisabled}
            onClick={paymentRequest}
            isLoading={isLoading}
          >
            {t('cover.pay')}
          </Button>
        </GridDiv>
      </RenderIf>
    </>
  )
}
