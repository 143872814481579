import axios, { Axios } from 'axios'
import { Auth } from '@aws-amplify/auth'
import { isAuth } from '../utils/isAuth'
import history from '../utils/history'

export const INDEXED_DB_TABLE_NAME = `${location?.host}_REQUESTS`
export const INDEXED_DB_TABLE_VERSION = 4
export const INDEXED_DB_GET_TABLE = 'GET'

const NETWORK_ERROR_MESSAGE = 'Network Error'
const REQUEST_ERROR_MESSAGE = 'Request Error'

export class axiosInstance {
  public axiosInstance: Axios

  constructor(endpointToSet: string) {
    this.axiosInstance = axios.create({
      baseURL: endpointToSet,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async get(path = '/', withToken = false, cacheFirst = false): Promise<any> {
    let res: any
    if (!navigator.onLine) {
      res = await this.getFromCache(path)
    }
    if (navigator.onLine && !cacheFirst) {
      res = await this.networkRequest('get', path, withToken)
    }
    if (navigator.onLine && cacheFirst) {
      res = await this.cacheFirst(path, withToken)
    }
    return res
  }

  async post(path = '/', payload: any, withToken = false): Promise<any> {
    let res: any
    if (navigator.onLine) {
      res = await this.networkRequest('post', path, withToken, payload)
    }
    if (!navigator.onLine) {
      // there is no cache for POST-requests
      // res = await this.getFromCache(INDEXED_DB_POSTS_TABLE, path)
      history.push('/network-error')
    }
    return res
  }

  async networkRequest(
    method: 'get' | 'post',
    path = '/',
    withToken: boolean,
    payload?: any
  ) {
    try {
      const jwt = await this.getJWT(withToken)

      let data: any

      if (method === 'get') {
        const result = await this.axiosInstance.get(path, {
          headers: {
            Authorization: jwt,
          },
        })
        data = result.data.data
        this.saveToCache(path, data)
      }

      if (method === 'post') {
        const result = await this.axiosInstance.post(path, payload, {
          headers: {
            Authorization: jwt,
          },
        })
        data = result.data.data
      }

      return data
    } catch (error: any) {
      if (error.message === NETWORK_ERROR_MESSAGE) {
        history.push('/network-error')
      } else {
        console.log('error:', error)
        throw new Error(REQUEST_ERROR_MESSAGE)
      }
    }
  }

  async cacheFirst(url = '/', withToken = false) {
    const thisNetworkRequest = this.networkRequest.bind(this)
    const promise = new Promise(function (resolve, reject) {
      const indexed = indexedDB.open(
        INDEXED_DB_TABLE_NAME,
        INDEXED_DB_TABLE_VERSION
      )
      indexed.onupgradeneeded = () => {
        indexed.result.createObjectStore(INDEXED_DB_GET_TABLE, {
          keyPath: 'request',
        })
      }
      indexed.onsuccess = () => {
        const requests = indexed.result
          .transaction(INDEXED_DB_GET_TABLE, 'readonly')
          .objectStore(INDEXED_DB_GET_TABLE)
        const cursorRequest = requests.get(url)
        cursorRequest.onsuccess = async (e: any) => {
          if (e?.target?.result?.response) {
            thisNetworkRequest('get', url, withToken)
            resolve(JSON.parse(e.target.result.response))
          } else {
            try {
              const res = await thisNetworkRequest('get', url, withToken)
              resolve(res)
            } catch (e: any) {
              reject(e)
            }
          }
        }
        cursorRequest.onerror = async (e: any) => {
          const res = await thisNetworkRequest('get', url, withToken)
          resolve(res)
        }
      }
      indexed.onerror = async () => {
        const res = await thisNetworkRequest('get', url, withToken)
        resolve(res)
      }
    })
    const result = await promise
    return result
  }

  async getFromCache(url = '/') {
    const promise = new Promise(function (resolve) {
      const indexed = indexedDB.open(
        INDEXED_DB_TABLE_NAME,
        INDEXED_DB_TABLE_VERSION
      )
      indexed.onupgradeneeded = () => {
        indexed.result.createObjectStore(INDEXED_DB_GET_TABLE, {
          keyPath: 'request',
        })
      }
      indexed.onsuccess = () => {
        const requests = indexed.result
          .transaction(INDEXED_DB_GET_TABLE, 'readonly')
          .objectStore(INDEXED_DB_GET_TABLE)
        const cursorRequest = requests.get(url)
        cursorRequest.onsuccess = (e: any) => {
          if (e?.target?.result?.response) {
            resolve(JSON.parse(e.target.result.response))
          } else {
            resolve(null)
          }
        }
        cursorRequest.onerror = (e: any) => {
          history.push('/network-error')
        }
      }
    })
    const result = await promise
    return result
  }

  async getJWT(withToken = false): Promise<string | 0> {
    let jwt: string | 0 = 0
    if (withToken) {
      const isUserAuth = await isAuth()
      if (isUserAuth) {
        const currentSession = await Auth?.currentSession()
        const accessToken = currentSession?.getIdToken()
        jwt = accessToken?.getJwtToken()
      }
    }
    return jwt
  }

  async saveToCache(path = '/', dataToSave: any) {
    const indexed = indexedDB.open(
      INDEXED_DB_TABLE_NAME,
      INDEXED_DB_TABLE_VERSION
    )
    indexed.onupgradeneeded = () => {
      indexed.result.createObjectStore(INDEXED_DB_GET_TABLE, {
        keyPath: 'request',
      })
    }
    indexed.onsuccess = () => {
      const posts = indexed.result
        .transaction(INDEXED_DB_GET_TABLE, 'readwrite')
        .objectStore(INDEXED_DB_GET_TABLE)
      if (dataToSave) {
        posts.put({
          request: path || '/',
          response: JSON.stringify(dataToSave),
        })
      }
    }
  }
}
