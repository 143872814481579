import { useMySelector } from '../../hooks/useMySelector'
import { Text } from './style'

export const TermsConditions = () => {
  const DSVGO = useMySelector(
    (state) => state?.app?.termsAndConditions?.dsvgoText
  )

  return (
    <div>
      <Text>{DSVGO}</Text>
    </div>
  )
}
