import { MWgetPathes } from '../ApiGETPathes'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { mobileWaiterInstance } from '.'
import {
  ExchangeStatusResType,
  Statustype,
  TableStatusResponseType,
} from './types'

const withToken = true
const withoutToken = false

export class mobileWaiterGET {
  public static async tableStatus(
    tableId: string | number
  ): Promise<TableStatusResponseType> {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const registerParam = `${registerId ? `&registerId=${registerId}` : ''}`
    const tableParam = `${tableId ? `&tableId=${tableId}` : ''}`
    const res: TableStatusResponseType = await mobileWaiterInstance.get(
      `${MWgetPathes.GET_TABLE_STATUS}?companyId=${guid}${registerParam}${tableParam}`,
      withoutToken
    )
    return res
  }

  public static async exchangeStatus(
    requestGUID: string
  ): Promise<ExchangeStatusResType> {
    const res: ExchangeStatusResType = await mobileWaiterInstance.get(
      `${MWgetPathes.GET_EXCHANGE_STATUS}/${requestGUID}`,
      withoutToken
    )
    return res
  }
}
