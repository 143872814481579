import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, ORANGE_COLOR } from './types'

export const AppSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    fetchAppData(state = initialState, action: PayloadAction<any>) {
      return (state = {
        ...state,
        isAppInit: action.payload.isAppInit,
        companyData: action.payload.companyData || state.companyData,
        address: action.payload.address || state.address,
        options: action.payload.options || state.options,
        menu: action.payload.menu || state.menu,
        interfaceStyles:
          action.payload.interfaceStyles || state.interfaceStyles,
        caption_list: action.payload.caption_list || state.caption_list,
        schedule: action.payload.schedule || state.schedule,
        termsAndConditions:
          action.payload.termsAndConditions || state.termsAndConditions,
        reservationSettings:
          action.payload.reservationSettings || state.reservationSettings,
      })
    },
    setIsProductsLoaded(state = initialState, action: PayloadAction<boolean>) {
      return (state = {
        ...state,
        isProductsLoaded: action.payload,
      })
    },
    setIsPaymentAvailable(
      state = initialState,
      action: PayloadAction<boolean>
    ) {
      return (state = {
        ...state,
        isPaymentAvailable: action.payload,
      })
    },
    updateColor(state = initialState) {
      return (state = {
        ...state,
        interfaceStyles: {
          ...state.interfaceStyles,
          colour: ORANGE_COLOR,
        },
      })
    },
    updateLastActiveCategoryId(
      state = initialState,
      action: PayloadAction<string>
    ) {
      return (state = {
        ...state,
        lastActiveCategoryId: action.payload,
      })
    },
  },
})

export default AppSlice.reducer
