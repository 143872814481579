import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/AuthReducer'
import appReducer from './reducers/AppReducer'
import translationsReducer from './reducers/TranslationsReducer'
import commonReducer from './reducers/CommonReducer'
import categoriesReducer from './reducers/CategoriesReducer'
import productsReducer from './reducers/ProductsReducer'
import modalsReducer from './reducers/ModalsReducer'
import selectedProductsReducer from './reducers/SelectedProductsReducer'
import filtersReducer from './reducers/FiltersReducer'
import restaurantsReducer from './reducers/RestaurantsReducer'
import specialsReducer from './reducers/SpecialsReducer'
import wishlistSlice from './reducers/WishlistReducer'
import tableReservationReducer from './reducers/TableReservation'
import { loadingBarReducer } from 'react-redux-loading-bar'

export const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  translations: translationsReducer,
  common: commonReducer,
  categories: categoriesReducer,
  products: productsReducer,
  modals: modalsReducer,
  selectedProducts: selectedProductsReducer,
  filters: filtersReducer,
  restaurants: restaurantsReducer,
  specials: specialsReducer,
  wishlist: wishlistSlice,
  loadingBar: loadingBarReducer,
  tableReservation: tableReservationReducer,
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
