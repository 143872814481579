import { useSearchParams } from 'react-router-dom'
import { useMySelector } from './useMySelector'
import { qrMenuPOST } from '../api/qrMenu/POST'
import { useOpenModal } from './useOpenModal'
import { Payment } from '../sections/MainScreen/Profile/Payment'
import { ReceiptModal } from '../components/ReceiptModal'
import { useMyDispatch } from './useMyDispatch'
import { useTranslations } from './useTranslations'
import { SelectedProductsSlice } from '../store/reducers/SelectedProductsReducer'
import { Card } from '../sections/MainScreen/Profile/Card'
import { useOpenNoReceiptModal } from './useOpenNoReceiptModal'
import { clickCollectPOST } from '../api/clickCollect/POST'

export type useCompleteDataTransType = () => void

export const useCompleteDataTrans = (): useCompleteDataTransType => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const openNoReceiptModal = useOpenNoReceiptModal()
  const [searchParams, setSearchParams] = useSearchParams()

  // Store
  const { isAppInit } = useMySelector((state) => state.app)
  const { discardSelectedProducts } = SelectedProductsSlice.actions

  // Variables
  const show_card = searchParams.get('show_card')
  const order_guid = searchParams.get('orderGuid')
  const datatransTrxId = searchParams.get('datatransTrxId')
  const show_payment_variant = searchParams.get('show_payment_variant')

  // Functions
  const completeDataTrans = async () => {
    try {
      if (
        show_payment_variant &&
        !show_card &&
        (datatransTrxId || order_guid) &&
        isAppInit
      ) {
        completeCreditCardUpdate()
      }
      if (
        show_card &&
        !show_payment_variant &&
        (datatransTrxId || order_guid) &&
        isAppInit
      ) {
        completeCardRefill()
      }
      if (
        !show_card &&
        !show_payment_variant &&
        (datatransTrxId || order_guid) &&
        isAppInit &&
        process.env.REACT_APP_IS_CLIC === '0'
      ) {
        openReceiptModal()
      }
      if (
        !show_card &&
        !show_payment_variant &&
        (datatransTrxId || order_guid) &&
        isAppInit &&
        process.env.REACT_APP_IS_CLIC === '1' &&
        order_guid
      ) {
        openReceiptModalClick()
      }
    } catch (e) {
      searchParams.delete('show_payment_variant')
      searchParams.delete('datatransTrxId')
      searchParams.delete('order_guid')
      searchParams.delete('orderGuid')
      searchParams.delete('show_card')
      setSearchParams(searchParams)
    }
  }

  const completeCreditCardUpdate = async () => {
    const success = await qrMenuPOST.completeNewPaymentVariant(
      datatransTrxId as string
    )
    if (success) {
      openModal({
        id: 'USER_CREDIT_CARD',
        title: '',
        components: [() => <Payment />],
      })
    }
    searchParams.delete('show_payment_variant')
    searchParams.delete('datatransTrxId')
    setSearchParams(searchParams)
  }

  const completeCardRefill = async () => {
    const success = await clickCollectPOST.refillCardComplete(
      datatransTrxId,
      order_guid
    )
    if (success) {
      openModal({
        id: 'USER_CARD',
        title: '',
        components: [() => <Card />],
      })
    }
    searchParams.delete('show_card')
    searchParams.delete('datatransTrxId')
    setSearchParams(searchParams)
  }

  const openReceiptModal = async () => {
    openModal({
      id: 'RECEIPT_MODAL',
      title: t('order.toMenu'),
      components: [
        ({ closeThisModal }) => (
          <ReceiptModal
            onCloseModal={() => {
              closeThisModal()
              searchParams.delete('datatransTrxId')
              searchParams.delete('order_guid')
              searchParams.delete('table')
              setSearchParams(searchParams)
              clearLocalStorage()
              dispatch(discardSelectedProducts())
            }}
          />
        ),
      ],
      onClose: () => {
        searchParams.delete('datatransTrxId')
        searchParams.delete('order_guid')
        searchParams.delete('table')
        setSearchParams(searchParams)
        clearLocalStorage()
        dispatch(discardSelectedProducts())
      },
    })
  }

  const openReceiptModalClick = async () => {
    openNoReceiptModal()
  }

  const clearLocalStorage = () => {
    window.localStorage.removeItem('products_notes')
    window.localStorage.removeItem('pickUpDelay')
    window.localStorage.removeItem('pickUpTime')
  }

  return completeDataTrans
}
