import { useState, useEffect, FC } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Input } from '../../../../components/Input'
import { handleLocalStorage } from '../../../../utils/handleLocalStorage'
import { PhoneNumberInput } from '../../../../components/Input/PhoneNumberInput'

import list from '../../../../assets/payment/list.svg'
import branch from '../../../../assets/payment/branch.svg'
import { TableDiv } from '../../styled'
import {
  Instructions,
  Instruction_Item,
  Instruction_Text,
  Point,
  SelfService_Container,
  Steps,
  Step_Divider,
  Step_Item,
} from './styled'
import { CheckBox } from '../../../../components/CheckBox'
import { useOpenModal } from '../../../../hooks/useOpenModal'
import { TermsConditions } from '../../../TermsConditions'
import { isAuth } from '../../../../utils/isAuth'
import { RenderIf } from '../../../../components/RenderIf'
import { AGBModal } from '../../../TermsConditions/AGB'
import { CCProvider } from '../../../../utils/getCCProviderIcon'
import { Spinner } from '../../../../components/Spinner'
import { useTranslations } from '../../../../hooks/useTranslations'
import { TermsAndConditionsCheckbox } from '../../../TermsConditions/checkBox'

type SelfServiceProps = {
  enablePayButton: (value: boolean) => void
  setIsFooterShown: (value: boolean) => void

  useSavedCard: boolean
  setUseSavedCard: (value: boolean) => void
  updateActiveCard: boolean
  setUpdateActiveCard: (value: boolean) => void
}

export const SelfService: FC<SelfServiceProps> = ({
  enablePayButton,
  setIsFooterShown,

  useSavedCard,
  setUseSavedCard,
  updateActiveCard,
  setUpdateActiveCard,
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()

  // State
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isTermsChecked, setIsTermsChecked] = useState(false)

  const [isInit, setIsInit] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)

  const [userCardData, setUserCardData] = useState<any>()

  // useEffect
  useEffect(() => {
    initUserData()
  }, [])

  useEffect(() => {
    if (name.length) {
      handleLocalStorage({ clickCollectName: name })
    }
  }, [name])

  useEffect(() => {
    if (phoneNumber.length) {
      handleLocalStorage({ clickCollectPhone: phoneNumber })
    }
  }, [phoneNumber])

  useEffect(() => {
    if (isInit && !isAuthorized) {
      if (name.length && phoneNumber.length > 6 && isTermsChecked) {
        enablePayButton(isTermsChecked)
      } else {
        enablePayButton(false)
      }
    }
  }, [name, phoneNumber, isTermsChecked])

  // Functions
  const initUserData = async () => {
    //
    const isUserAuth = await isAuth()

    if (isUserAuth) {
      const currentUserInfo = await Auth.currentUserInfo()
      const attributes = currentUserInfo?.attributes
      if (attributes) {
        setName(attributes.name)
        setPhoneNumber(attributes.phone_number)
        if (attributes?.['custom:active_card_token']) {
          setUserCardData(JSON.parse(attributes?.['custom:active_card_token']))
          setUseSavedCard(true)
        }
      }
    }
    if (!isUserAuth) {
      const clickCollectName = handleLocalStorage('clickCollectName')
      const clickCollectPhone = handleLocalStorage('clickCollectPhone')
      if (clickCollectName?.length) {
        setName(clickCollectName)
      }
      if (clickCollectPhone?.length) {
        setPhoneNumber(clickCollectPhone)
      }
    }

    setIsAuthorized(!!isUserAuth)
    setIsInit(true)
  }

  const toggleCheckBox = (value: boolean) => {
    if (name.length && phoneNumber.length && value) {
      setIsTermsChecked(value)
    } else {
      setIsTermsChecked(false)
    }
  }

  const openTermsAndConditions = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'TERMS_AND_CONDITIONS',
      title: 'den AGB',
      components: [() => <TermsConditions />],
    })
  }

  const openAGB = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'AGB_MODAL',
      title: 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
      components: [() => <AGBModal />],
    })
  }

  const handleInputFocus = () => {
    setIsFooterShown(false)
  }

  const handleInputBlur = () => {
    setIsFooterShown(true)
  }

  return (
    <SelfService_Container>
      <TableDiv>{t('selfService.form')}</TableDiv>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
        <Steps>
          <Step_Item>
            <img src={list} alt="Products" />
          </Step_Item>
          <Step_Divider />
          <Step_Item>SMS</Step_Item>
          <Step_Divider />
          <Step_Item>
            <img src={branch} alt="Branch" />
          </Step_Item>
        </Steps>
      </RenderIf>
      <Instructions>
        <RenderIf condition={!isInit}>
          <Spinner type="alternate" />
        </RenderIf>
        <RenderIf condition={isInit}>
          {/* TODO: consider using scroll into view for small devices */}
          <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
            <Instruction_Text>
              {t('selfService.leaveNameIdetify')}
            </Instruction_Text>
            <br />
          </RenderIf>

          <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
            <Instruction_Item>
              <Point>1</Point>
              <Instruction_Text>
                {t('selfService.leaveNameText')}
              </Instruction_Text>
            </Instruction_Item>
          </RenderIf>
          <Input
            value={name}
            placeholder={t('clickCollect.nameEnter')}
            onChange={(e: any) => setName(e.currentTarget.value)}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          <br />
          <PhoneNumberInput
            value={phoneNumber}
            onChange={(newValue) => setPhoneNumber(newValue)}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />

          <RenderIf condition={isInit && isAuthorized && !!userCardData}>
            <br />
            <CheckBox
              isChecked={useSavedCard}
              onClick={setUseSavedCard}
              label={
                <span>
                  <span>{t('selfService.useSavedCard')}</span>
                  {' ( '}
                  <CCProvider cardMask={userCardData?.masked} />{' '}
                  <span>
                    {t('selfService.withEnding')}{' '}
                    {userCardData?.masked?.split('x').pop()}
                    {' )'}
                  </span>
                </span>
              }
            />
            <br />
            <CheckBox
              isChecked={updateActiveCard}
              onClick={setUpdateActiveCard}
              label={t('selfService.newCardAsDefault')}
              isDisabled={useSavedCard}
            />
          </RenderIf>
          <RenderIf condition={isInit && isAuthorized && !userCardData}>
            <br />
            <CheckBox
              isChecked={updateActiveCard}
              onClick={setUpdateActiveCard}
              label={t('selfService.saveNewCard')}
            />
          </RenderIf>

          <RenderIf condition={isInit && !isAuthorized}>
            <br />
            <TermsAndConditionsCheckbox
              isChecked={isTermsChecked}
              handleCheck={toggleCheckBox}
            />
            <br />
          </RenderIf>
          <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
            <Instruction_Item>
              <Point>2</Point>
              <Instruction_Text>{t('selfService.receiveSMS')}</Instruction_Text>
            </Instruction_Item>
            <Instruction_Item>
              <Point>3</Point>
              <Instruction_Text>
                {t('selfService.pickUpAtStand')}
              </Instruction_Text>
            </Instruction_Item>
          </RenderIf>
        </RenderIf>
      </Instructions>
    </SelfService_Container>
  )
}
