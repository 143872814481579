import { useMySelector } from '../../hooks/useMySelector'
import { Text } from './style'

export const AGBModal = () => {
  const AGB = useMySelector((state) => state?.app?.termsAndConditions?.agbText)

  return (
    <div>
      <Text>{AGB}</Text>
    </div>
  )
}
