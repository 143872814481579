import { FC, useState, useEffect } from 'react'
import { useMySelector } from '../../../hooks/useMySelector'
import { ICategory } from '../../../store/reducers/CategoriesReducer/types'
import { RenderIf } from '../../../components/RenderIf'
import { CategoryItem } from './CategoryItem'
import { Specials } from '../Specials'
import { CategoriesList, EmptyBlock } from './styled'
import { NotFoundMessage } from '../../../components/commonStyles'
import { AppSlice } from '../../../store/reducers/AppReducer'
import { useMyDispatch } from '../../../hooks/useMyDispatch'
import { useTranslations } from '../../../hooks/useTranslations'
import { getContrastColor } from '../../../utils/getContrastColor'

type CategoriesProps = {
  isInputShowing: boolean
  categories: ICategory[]
  isAllergensShowing: boolean
}

export const Categories: FC<CategoriesProps> = ({
  isInputShowing = false,
  categories,
  isAllergensShowing = false,
}) => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()

  // Store
  const { updateLastActiveCategoryId } = AppSlice.actions
  const { isProductsLoaded, interfaceStyles } = useMySelector(
    (state) => state.app
  )

  // State
  const [captionObserver, setCaptionObserver] = useState<any>(null)
  const [categoryObserver, setCategoryObserver] = useState<any>(null)

  // TODO: create useIntersectionObserver hook
  useEffect(() => {
    const captionObserverToSet = new IntersectionObserver(
      (items) => {
        if (items[0].isIntersecting) {
          const target: any = document.getElementsByClassName(
            `SLIDER_BUTTON--TARGET`
          )[0]

          const activeElements: any = document.querySelectorAll(
            `.SLIDER_BUTTON--ACTIVE`
          )

          if (activeElements?.length) {
            Array.prototype.findIndex.call(activeElements, (element: any) => {
              element.style = null
              element.classList.remove('SLIDER_BUTTON--ACTIVE')
            })
          }

          if (target && `slider_${items[0].target.id}` === target?.id) {
            target.style['background'] = interfaceStyles.colour
            target.classList.add('SLIDER_BUTTON--ACTIVE')
            target.classList.remove('SLIDER_BUTTON--TARGET')
          }
          if (!target) {
            const sliderElement: any = document.getElementById(
              `slider_${items[0].target.id}`
            )
            if (sliderElement) {
              sliderElement.style['background'] = interfaceStyles.colour
              sliderElement.style['color'] = getContrastColor(
                interfaceStyles.colour
              )
              sliderElement.classList.add('SLIDER_BUTTON--ACTIVE')
              dispatch(
                updateLastActiveCategoryId(`slider_${items[0].target.id}`)
              )
              sliderElement.scrollIntoView({
                inline: 'center',
                behavior: 'auto',
              })
            }
          }
        }
      },
      {
        rootMargin: '-139px 0px -75% 0px',
      }
    )
    setCaptionObserver(captionObserverToSet)
  }, [interfaceStyles])

  useEffect(() => {
    const categoriesObserverToSet = new IntersectionObserver(
      (items) => {
        if (items[0].isIntersecting && items[0]?.boundingClientRect?.top < 0) {
          const target = document.getElementsByClassName(
            `SLIDER_BUTTON--TARGET`
          )[0]
          const idToFind = items[0].target.id.replace('container', 'slider')
          const sliderElement = document.getElementById(idToFind)
          if (!target && sliderElement) {
            const activeElements: any = document.querySelectorAll(
              `.SLIDER_BUTTON--ACTIVE`
            )

            if (activeElements?.length) {
              Array.prototype.findIndex.call(activeElements, (element: any) => {
                element.style = null
                element.classList.remove('SLIDER_BUTTON--ACTIVE')
              })
            }
            sliderElement.style['background'] = interfaceStyles.colour
            sliderElement.style['color'] = getContrastColor(
              interfaceStyles.colour
            )
            sliderElement.classList.add('SLIDER_BUTTON--ACTIVE')
            sliderElement.scrollIntoView({
              inline: 'center',
              behavior: 'auto',
            })
          }
        }
      },
      {
        rootMargin: '-100% 0px 0px 0px',
      }
    )
    setCategoryObserver(categoriesObserverToSet)
  }, [interfaceStyles])

  useEffect(() => {
    const listOfContainers = document.getElementsByClassName(
      `list_category__container`
    )
    Array.prototype.forEach.call(listOfContainers, (category: any) => {
      categoryObserver?.observe(category)
    })
    return () =>
      Array.prototype.forEach.call(listOfContainers, (category: any) => {
        categoryObserver?.unobserve(category)
      })
  }, [categories, categoryObserver])

  useEffect(() => {
    const listOfCategories = document.getElementsByClassName(`list_category`)

    Array.prototype.forEach.call(listOfCategories, (category: any) => {
      captionObserver?.observe(category)
    })

    return () =>
      Array.prototype.forEach.call(listOfCategories, (category: any) => {
        captionObserver?.unobserve(category)
      })
  }, [categories, captionObserver])

  // Functions
  const getCustomHeight = (
    isInputShowing: boolean,
    isAllergensShowing: boolean
  ) => {
    let height = 130
    if (isAllergensShowing) height += 34
    if (isInputShowing) height += 60
    if (process.env.REACT_APP_IS_CLIC === '1') height += 113
    return `${height}px`
  }

  return (
    <CategoriesList>
      <EmptyBlock
        height={getCustomHeight(isInputShowing, isAllergensShowing)}
      />
      <Specials />
      {categories?.map((category) => (
        <RenderIf key={category.id} condition={!!category?.products?.length}>
          <CategoryItem key={category.id} category={category} />
        </RenderIf>
      ))}
      <RenderIf condition={!isProductsLoaded}>
        <p>{t('common.loading')}</p>
      </RenderIf>
      <RenderIf
        condition={
          isProductsLoaded && !categories.find((el) => el.products?.length)
        }
      >
        <NotFoundMessage>{t('errors.didNotFind')}</NotFoundMessage>
      </RenderIf>
      <EmptyBlock />
    </CategoriesList>
  )
}
