import styled from 'styled-components'

type SideBarProps = {
  isSidebarShowing?: boolean
}

type CoverInnerBlockProps = {
  withInstructions?: boolean
}

type NotificationsProps = {
  counter?: string | number
}

export const CoverStyled = styled.div`
  background-color: #434343;
  background-image: ${({ theme }) => `url(${theme.backgroundImage})`};
  background-position: center top;
  background-size: cover;
  width: 100vw;
  top: 0;
  bottom: 0;
  right: 0;
  transition: right 0.25s;
  position: fixed;
  z-index: 3;
`

export const CoverSidebar = styled('div')<SideBarProps>`
  height: 100vh;
  width: 300px;
  position: absolute;
  background: ${({ theme }) => theme.MainBackGround};
  top: 0;
  left: ${({ isSidebarShowing }) => (isSidebarShowing ? '0%' : '-100%')};
  transition: left 0.5s;
  position: absolute;
  z-index: 4;
`

export const VideoBackground = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  position: absolute;
`

export const SidebarCloser = styled('div')<SideBarProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100vw - 300px);
  height: 100%;
  right: ${({ isSidebarShowing }) => (isSidebarShowing ? '0%' : '100%')};
  transition: right 0.5s;
  z-index: 4;
`

export const CoverSidebarCloseHandler = styled('div')<SideBarProps>`
  position: absolute;
  top: 10px;
  left: ${({ isSidebarShowing }) =>
    isSidebarShowing ? 'calc(100% - 48px)' : '-150%'};
  transition: left 0.5s;
  transition-delay: 0.05s;
`

export const SidebarTitle = styled.p`
  color: #979797;
  margin: 0;
  margin-left: 15px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  height: 36px;
  padding-top: 14px;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
`

export const Navigator = styled.div`
  position: absolute;
  top: 15px;
  right: 40px;
`

export const SidebarItemContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 36px 1fr;
  border-bottom: 1px solid #c4c4c4;
  z-index: 4;
`

export const SidebarItemTextContainer = styled.div`
  padding: 15px 0;
  position: relative;
`

export const SidebarItem = styled.p`
  color: #555;
  margin: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.FontColor};
`

export const SidebarLink = styled.a`
  color: #555;
  margin: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.FontColor};
  display: block;
  text-decoration: underline;
  &:visited {
    color: ${({ theme }) => theme.FontColor};
  }
  &:active {
    color: ${({ theme }) => theme.colour};
  }
  &:hover {
    color: ${({ theme }) => theme.colour};
  }
`

export const SidebarButtonContainer = styled.div`
  position: absolute;
  padding: 5vw;
  top: 0;
  left: 0;
`

export const NotificationsButtonContainer = styled('div')<NotificationsProps>`
  position: absolute;
  padding: 5vw;
  top: 0;
  right: 0;
  color: #fff;
  &::after {
    content: ${({ counter }) => (counter ? `'${counter}'` : 'unset')};

    background: red;
    position: absolute;
    z-index: 0;

    height: 18px;
    width: 18px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 4vw;
    right: 4.5vw;
    font-size: 12px;
    font-weight: bold;
  }
`

export const CoverInnerBlock = styled('div')<CoverInnerBlockProps>`
  width: 100%;
  height: 50%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 58px 58px 1fr;
  grid-template-rows: ${({ withInstructions }) =>
    `58px ${withInstructions ? '110px' : '58px'} 58px 1fr`};
  grid-gap: ${({ withInstructions }) => (withInstructions ? '15px' : '30px')};
  justify-items: center;
`

export const InstructionsBlock = styled.div`
  color: #fff;
  max-width: 210px;
  p {
    font-size: 12px;
    margin-bottom: 8px;
  }
`

export const CoverLogo = styled.img`
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 75%;
  margin: auto;
  grid-row: 1 / -1;
`

export const RestaurantInfoBanner = styled.p`
  font-weight: 600;
  font-size: 18px;
  height: 56px;
  width: 100%;
  line-height: 56px;
  text-align: center;
  color: ${({ theme }) => theme.FontColor};
  border-bottom: 1px solid #c4c4c4;
`

export const AGBText = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  color: ${({ theme }) => theme.FontColor};
  & span {
    text-decoration: underline;
  }
`
